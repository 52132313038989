import { Platform, MenuController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import {AppConfig} from '../../../app/app.config';
import { Storage } from '@ionic/storage';
import { BehaviorSubject } from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Events } from '@ionic/angular';

const TOKEN_KEY = 'auth-token';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  authenticationState = new BehaviorSubject(false);

  constructor(public http: HttpClient, private storage: Storage, private plt: Platform, public events: Events, public menuCtrl: MenuController) { 
    this.plt.ready().then(() => {
      this.checkToken();
    });
  }

  checkToken() {
    this.storage.get(TOKEN_KEY).then(res => {
      if (res) {
        this.authenticationState.next(true);
      }
    })
  }
 
  async login(user) {
    localStorage.clear();
    let respuesta = null;
    let headers = new HttpHeaders().set('Accept','application/json');
    headers = headers.append('Content-Type', 'application/json');
   
    try {
      console.log('user',JSON.stringify(user));
      respuesta = await this.http.post(AppConfig.API_ENDPOINT + 'api/auth/login', JSON.stringify(user),  {headers: headers}).toPromise();
    } catch (err) {
      respuesta = err;
    }
  
    if(respuesta['status']===true){
      console.log('User created!')
      this.events.publish('user:created', respuesta['user'], Date.now());
      localStorage.setItem(TOKEN_KEY,'Bearer ' + respuesta['access_token']);
      localStorage.setItem('user',JSON.stringify(respuesta['user']));
      this.storage.set(TOKEN_KEY, 'Bearer ' + respuesta['access_token']).then(() => {
        this.authenticationState.next(true);
      }); 
    }
    return respuesta;
  }
 
  logout() {
    localStorage.clear();
    return this.storage.remove(TOKEN_KEY).then(() => {
      this.menuCtrl.close();
      this.authenticationState.next(false);
    });
  }
 
  isAuthenticated() {
    return this.authenticationState.value;
  }
}
