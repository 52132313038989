import { AuthGuard } from './api/guards/auth.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
  },
  {
    path: 'list',
    loadChildren: () => import('./list/list.module').then(m => m.ListPageModule)
  },
  { path: 'login', loadChildren: './auth/login/login.module#LoginPageModule' },
  { path: 'checklist', loadChildren: './checklist/checklist.module#ChecklistPageModule' },
  { path: 'inspect', loadChildren: './inspect/inspect.module#InspectPageModule' },
  { path: 'tracto', loadChildren: './tracto/tracto.module#TractoPageModule' },
  {
    path: 'members', 
    canActivate: [AuthGuard],
    loadChildren: './members/member-routing.module#MemberRoutingModule'
  },
  { path: 'historial', loadChildren: './historial/historial.module#HistorialPageModule' },
  { path: 'acoplado', loadChildren: './acoplado/acoplado.module#AcopladoPageModule' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
