import { Router } from '@angular/router';
import { Component } from '@angular/core';
import { AuthenticationService } from './api/services/authentication.service';

import { Platform, Events } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  public appPages = [
    {
      title: 'Nueva inspección',
      url: '/inspect',
      icon: 'add-circle'
    },
    {
      title: 'Historial de inspección',
      url: '/historial',
      icon: 'list'
    }
  ];

  user = {
    "name": "",
    "email": "",
    "document_number": "",
  };

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private authenticationService: AuthenticationService,
    private statusBar: StatusBar,
    private router: Router,
    public events: Events
  ) {
    this.initializeApp();
    events.subscribe('user:created', (user, time) => {
      // user and time are the same arguments passed in `events.publish(user, time)`
      console.log('Welcome', user, 'at', time);
      this.user = user;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.authenticationService.authenticationState.subscribe(state => {
        if (state) {
          this.router.navigate(['members', 'home']);
        } else {
          this.router.navigate(['login']);
        }
      });
      const user = localStorage.getItem('user');
      if(user){
        this.user = JSON.parse(user);
      }
    });
  }

  logout() {
    this.authenticationService.logout();
  }
}
