import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Storage } from '@ionic/storage';

const TOKEN_KEY = 'auth-token';
@Injectable()
export class InterceptorService implements HttpInterceptor {

  constructor(public storage: Storage) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      // add authorization header with jwt token if available
      const token = localStorage.getItem(TOKEN_KEY);
      if(token){
        request = request.clone({
          setHeaders: {
            Authorization: token
          }
        });
      }
      return next.handle(request);
  }

  getToken() {
    return new Promise(function(resolve, reject) {
      this.storage.get(TOKEN_KEY).then(token=> resolve(token));
    });
  }

}
